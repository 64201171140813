export default [
  {
    key: 'media_id',
    image: 'image_url',
    label: 'Logo',
    type: 'image',
    required: true,
    notes: [
      'Wajib menggunakan gambar dengan <b>rasio kotak</b>.',
      'e.g. 480 x 480 px, 256 x 256 px, dll.',
    ],
  },
  {
    key: 'name',
    label: 'Nama',
    required: true,
    notes: [
      'Nama metode pembayaran yang akan tampil dihalaman pembelian.',
    ],
  },
  {
    key: 'payment_gateway',
    label: 'Kode Payment Gateway',
    type: 'radio',
    options: [
      { value: 'midtrans', text: 'Midtrans' },
      { value: 'xendit', text: 'Xendit' },
      { value: 'manual', text: 'Manual' },
    ],
    required: true,
    notes: [
      'Payment Gateway yang akan digunakan.',
    ],
  },
  {
    key: 'code',
    label: 'Kode',
    type: 'select',
    options: [
      { value: 'bca', text: 'BCA' },
      { value: 'bni', text: 'BNI' },
      { value: 'bri', text: 'BRI' },
      { value: 'mandiri', text: 'Mandiri' },
      { value: 'permata', text: 'Permata' },
      { value: 'gopay', text: 'Gopay' },
      { value: 'creditcard', text: 'Credit Card' },
      { value: 'manual', text: 'Manual' },
      { value: 'qris', text: 'QRIS' },
      { value: 'dana', text: 'DANA' },
    ],
    required: true,
    notes: [
      'Kode yang mengidentifikasi metode pembayaran sesuai dengan payment gateway yang dipilih.',
    ],
  },
  {
    key: 'category',
    label: 'Kode Kategori',
    required: true,
    notes: [
      'Kategori pembayaran yang akan ditampilkan.',
    ],
  },
  {
    key: 'snap_code',
    label: 'Kode Snap',
    notes: [
      'Kode snap yang digunakan untuk transaksi pada Midtrans maupun Xendit',
    ],
  },
  {
    key: 'fixed_fee',
    label: 'Biaya Tetap',
    type: 'number',
    prepend: 'Rp',
    min: 0,
    required: true,
    notes: [
      'Biaya tetap yang akan dikenakan pada setiap transaksi.',
    ],
  },
  {
    key: 'percentage_fee',
    label: 'Biaya Persentase',
    type: 'number',
    append: '%',
    min: 0,
    max: 100,
    required: true,
    notes: [
      'Potongan yang akan dikenakan berdasarkan persentase dari total transaksi.',
    ],
  },
  {
    key: 'is_active',
    label: 'Status',
    type: 'radio',
    required: true,
    notes: [
      'Metode pembayaran yang dapat digunakan oleh pembeli apabila Aktif.',
    ],
    options: [
      { value: true, text: 'Aktif' },
      { value: false, text: 'Tidak aktif' },
    ],
  },
]
